import * as React from 'react';

// Utils
import { t } from '@toolkit/util/i18n';
// Constants
// Actions
// Models
import { OptionModel } from '@src/shared/src/models';
// Interfaces
// Components
import Switch from './Switch';
import { SidebarSection } from '@toolkit/ui';
// Styles

type Props = {
  title:string;
  vehicles:OptionModel[];
  isDisabled?:boolean;
  onChange:(vehicles:OptionModel[]) => void;
};

export default class TransportationFilter extends React.PureComponent<Props> {

  public render() {
    const { title, vehicles, isDisabled } = this.props;
   
    return (
      <SidebarSection title={title} isDisabled={isDisabled}>
        {vehicles.map((vehicle:OptionModel) =>
          <Switch
            key={`kid-${vehicle.label}`}
            label={t(`TransportationFilter.switch.label.${vehicle.label.toLowerCase()}`)}
            icon={`icon-${vehicle.label.toLowerCase()}`}
            isChecked={Boolean(vehicle.value)}
            onChange={(val) => {
              const nVehicles:any = vehicles.map((veh:OptionModel) =>
                (veh.label === vehicle.label) ?
                  {
                    label: veh.label,
                    value: val,
                  }
                  :
                  veh
              );
              this.props.onChange(nVehicles);
            }
          }/>
        )}
      </SidebarSection>
    );
  }
}
