// NOTE: Currenlty this component can only be used with HotelFeatureFilter!
import * as React from 'react';
import { useSelector } from 'react-redux';
import { SelectionModel } from '@src/models';
import { CheckboxFilter } from './CheckboxFilter';
import { IRootState } from '@src/store';
import { SidebarSection } from '@toolkit/ui';

type Props = {
  title: string;
  options: SelectionModel[];
  isOpen?: boolean;
  isDisabled?: boolean;
  onChange(newOptions: SelectionModel[]): void;
  categoryTranslation?(categoryName: string): string;
};

export const CheckboxSelectionFilter: React.FC<Props> = (props) => {
  //TODO: remove when we have language sorted out
  const userLanguage = useSelector(
    (state: IRootState) => state.adminUser.profile.preference.language,
  ).split('-')[0];

  const isSelectionCurrent = (selection: SelectionModel, current: SelectionModel) =>
    current.category === selection.category && current.enName === selection.enName;

  const onChangeSelection = (selection: SelectionModel, isSelected: boolean) => {
    const newOptions = props.options.map(option => isSelectionCurrent(selection, option)
      ? { category: option.category, enName: option.enName, deName: option.deName,  esName: option.esName, frName: option.frName, isSelected }
      : option
    )
    props.onChange(newOptions)
  }

  const onChangeSelectionOnly = (selection: SelectionModel) => {
    const newOptions = props.options.map(option => isSelectionCurrent(selection, option)
      ? { category: option.category, enName: option.enName, deName: option.deName, esName: option.esName, frName: option.frName, isSelected: true }
      : { category: option.category, enName: option.enName, deName: option.deName, esName: option.esName, frName: option.frName, isSelected: false }
    )
    props.onChange(newOptions)
  }

  const categories = Array.from(new Set(props.options.map((option) => option.category))).sort(
    (currOpt, _) => (currOpt === 'fare' ? -1 : 0),
  );

  if (props.options.length === 0) {
    return null;
  }

  return (
    <SidebarSection title={props.title} isDisabled={props.isDisabled}>
      {categories.map((category) => (
        <div className="sidebar-section-group" key={category}>
          <div className="sidebar-section-headline">
            <strong>
              {props.categoryTranslation ? props.categoryTranslation(category) : category}
            </strong>
          </div>
          {props.options
            .filter((option) => option.category === category)
            .map((option) => (
              <CheckboxFilter
                key={`sel-id-${option[`${userLanguage}Name`]}`}
                id={`sel-id-${option[`${userLanguage}Name`]}`}
                label={option[`${userLanguage}Name`]}
                isChecked={option.isSelected}
                onChange={(val) => onChangeSelection(option, val)}
                onChangeOnly={(val) => onChangeSelectionOnly(option)}
              />
            ))}
        </div>
      ))}
    </SidebarSection>
  );
};
