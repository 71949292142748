import * as React from 'react';
import { t } from '@toolkit/util/i18n';
import { SidebarSection } from '@toolkit/ui';

export interface IProps {
  title: string;
  currentValue: string;
  onChange: (value: string) => void;
}

export interface IState {
  currentValue: string;
}

export default class InputFilter extends React.Component<IProps, IState> {
  public state: IState = {
    currentValue: this.props.currentValue,
  };

  private onChangeInput = (e: any) => {
    this.setState({
      currentValue: e.target.value,
    });
    this.props.onChange(e.target.value);
  };

  public render() {
    return (
      <SidebarSection title={this.props.title}>
        <div className="search-field">
          <input
            type="text"
            value={this.state.currentValue}
            onChange={this.onChangeInput}
            placeholder={t('Hotel.InputFilter.placeholder')}
          />
        </div>
      </SidebarSection>
    );
  }
}
