import * as React from 'react';
import Slider, { Range } from 'rc-slider';

// Utils
import {
  formatCents,
  isNil,
  partialRight,
  prop,
  roundToOneDigit,
} from '@src/shared/src/util/general';
import { t } from '@toolkit/util/i18n';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

// Constants
import { CENTS_CONVERSION_TYPES } from '@src/shared/src/const/app';
// Actions
import { selectors } from '@src/shared/src';
import { appSettingsSelectors } from '@src/shared/src/selectors';
import {
  setHotelNameFilter,
  setHotelStarFilter,
  setHotelRatingFilter,
  setHotelPricePerNightFilter,
  setHotelPriceFilter,
  setHotelDistanceFilter,
  setHotelFeatureFilter,
  setHotelAccommodationFilter,
} from '@src/shared/src/actions/filterActions';
// Models
// Interfaces
import { IRootState } from '@src/store';

// Components
import { SearchSideBarInfo } from '@pod/search/components';
import { InputFilter, BaseFilter, CheckboxSelectionFilter } from '@pod/filters';
import { TripSideBarSelectionInfo } from '@pod/trips/components';
import { Sidebar, SidebarSection } from '@toolkit/ui';
import { FilterOptionList } from '@pod/filters/components';
import StarFilter from '@toolkit/ui/StarFilter';

// Styles
import '@src/styles/modules/sidebar/_search-field.scss';

type Props = {
  isOpen?: boolean;
  setHotelDetailsOpenedId: (val: string) => void;
};

const HotelSideBarConn: React.FC<Props> = ({isOpen, setHotelDetailsOpenedId }) => {
  const resetHotelDetailsOpenedId = () => setHotelDetailsOpenedId('');

  const dispatch = useDispatch();

  const hotelNameFilter = useSelector((state: IRootState) => state.filters.hotelNameFilter);
  const hotelStarFilter = useSelector((state: IRootState) => state.filters.hotelStarFilter);
  const hotelRatingFilter = useSelector((state: IRootState) => state.filters.hotelRatingFilter);
  const hotelPricePerNightFilter = useSelector((state: IRootState) => state.filters.hotelPricePerNightFilter);
  const hotelPriceFilter = useSelector((state: IRootState) => state.filters.hotelPriceFilter);
  const hotelDistanceFilter = useSelector((state: IRootState) => state.filters.hotelDistanceFilter);
  const hotelFeatureFilter = useSelector((state: IRootState) => state.filters.hotelFeatureFilter);
  const hotelAccommodationFilter = useSelector((state: IRootState) => state.filters.hotelAccommodationFilter);
  const isSearchingForOnlyHotel = useSelector((state: IRootState) => state.settings.isSearchingForOnlyHotel);
  const search = useSelector((state: IRootState) => selectors.search.search(state.search));
  const loyaltyCards = useSelector((state: IRootState) => appSettingsSelectors.loyaltyCards(state));
  const selectedOutward = useSelector((state: IRootState) => selectors.checkout.outwardTrip(state.checkout));
  const selectedInbound = useSelector((state: IRootState) => selectors.checkout.inboundTrip(state.checkout));
  const preventVacationRentals = useSelector((state: IRootState) => state.organization.org.preventVacationRentals);
  const totalHotelsCount = useSelector((state: IRootState) => state.hotels.hotels.length);

  return (
    <Sidebar isOpen={isOpen}>
      <SearchSideBarInfo
        from={prop('depName', search)}
        to={prop('arrName', search)}
        depAt={prop('depAt', search)}
        arrAt={prop('arrAt', search)}
        loyaltyCards={loyaltyCards}
        isSearchingForOnlyHotel={isSearchingForOnlyHotel}
      />
      {!isNil(selectedOutward) && (
        <TripSideBarSelectionInfo
          outward={selectedOutward}
          inbound={selectedInbound}
          searchId={search.id}
        />
      )}
      <div className="sidebar-inner">
        <InputFilter
          title={t('hotelSideBar.hotelNameFilter.title.searchForHotel')}
          currentValue={hotelNameFilter.current}
          onChange={(value) => {
            setHotelDetailsOpenedId('');
            dispatch(
              setHotelNameFilter({
                constraint: {
                  current: value,
                },
                onlyUpdateConstraint: false,
              }),
            );
          }}
        />

        {!preventVacationRentals && totalHotelsCount !== 0 && (
          <SidebarSection title={t('hotel-accommodation-filter.title')}>
            <FilterOptionList
              options={hotelAccommodationFilter.current}
              filterType="switch"
              onChange={(value) => {
                resetHotelDetailsOpenedId();
                dispatch(
                  setHotelAccommodationFilter({
                    constraint: {
                      current: value,
                    },
                    onlyUpdateConstraint: false,
                  }),
                );
              }}
              labelTranslation={(label) => t(`hotel-accommodation-filter.label.${label}`)}
            />
          </SidebarSection>
        )}
        <BaseFilter
          title={t('hotelSideBar.starsFilter.title.stars')}
          min={hotelStarFilter.min}
          max={hotelStarFilter.max}
          currentMin={hotelStarFilter.currentMin}
          currentMax={hotelStarFilter.currentMax}
          edgeFormatValue={StarFilter}
          render={({ currentMax, currentMin, min, max, onChange }) => (
            <Range value={[currentMin, currentMax]} min={min} max={max} onChange={onChange} />
          )}
          onChange={(val) => {
            setHotelDetailsOpenedId('');
            dispatch(
              setHotelStarFilter({
                constraint: {
                  min: hotelStarFilter.min,
                  max: hotelStarFilter.max,
                  currentMin: val[0],
                  currentMax: val[1],
                },
                onlyUpdateConstraint: false,
              }),
            );
          }}
        />
        <BaseFilter
          title={t('hotelSideBar.ratingFilter.title.ratings')}
          min={hotelRatingFilter.min}
          max={hotelRatingFilter.max}
          currentMin={hotelRatingFilter.currentMin}
          currentMax={hotelRatingFilter.currentMax}
          edgeFormatValue={roundToOneDigit}
          midFormatValue={roundToOneDigit}
          render={({ currentMax, currentMin, min, max, onChange }) => (
            <Range
              value={[currentMin, currentMax]}
              min={min}
              max={max}
              step={0.1}
              onChange={onChange}
            />
          )}
          onChange={(value) => {
            setHotelDetailsOpenedId('');
            dispatch(
              setHotelRatingFilter({
                constraint: {
                  min: hotelRatingFilter.min,
                  max: hotelRatingFilter.max,
                  currentMin: value[0],
                  currentMax: value[1],
                },
                onlyUpdateConstraint: false,
              }),
            );
          }}
        />
        <BaseFilter
          title={t('hotelSideBar.pricePerNightFilter.title.pricePerNight')}
          min={hotelPricePerNightFilter.min}
          max={hotelPricePerNightFilter.max}
          currentMin={hotelPricePerNightFilter.currentMin}
          currentMax={hotelPricePerNightFilter.currentMax}
          edgeFormatValue={partialRight(formatCents, [CENTS_CONVERSION_TYPES.FLOOR])}
          midFormatValue={partialRight(formatCents, [CENTS_CONVERSION_TYPES.FLOOR])}
          render={({ currentMax, currentMin, min, max, onChange }) => (
            <Range value={[currentMin, currentMax]} min={min} max={max} onChange={onChange} />
          )}
          onChange={(val) => {
            setHotelDetailsOpenedId('');
            dispatch(
              setHotelPricePerNightFilter({
                constraint: {
                  min: hotelPricePerNightFilter.min,
                  max: hotelPricePerNightFilter.max,
                  currentMin: val[0],
                  currentMax: val[1],
                },
                onlyUpdateConstraint: false,
              }),
            );
          }}
        />
        <BaseFilter
          title={t('hotelSideBar.totalPriceFilter.title.totalPrice')}
          min={hotelPriceFilter.min}
          max={hotelPriceFilter.max}
          currentMin={hotelPriceFilter.currentMin}
          currentMax={hotelPriceFilter.currentMax}
          edgeFormatValue={partialRight(formatCents, [CENTS_CONVERSION_TYPES.FLOOR])}
          midFormatValue={partialRight(formatCents, [CENTS_CONVERSION_TYPES.FLOOR])}
          render={({ currentMax, currentMin, min, max, onChange }) => (
            <Range value={[currentMin, currentMax]} min={min} max={max} onChange={onChange} />
          )}
          onChange={(val) => {
            setHotelDetailsOpenedId('');
            dispatch(
              setHotelPriceFilter({
                constraint: {
                  min: hotelPriceFilter.min,
                  max: hotelPriceFilter.max,
                  currentMin: val[0],
                  currentMax: val[1],
                },
                onlyUpdateConstraint: false,
              }),
            );
          }}
        />
        <BaseFilter
          title={t('hotelSideBar.distanceFilter.title.distance')}
          min={hotelDistanceFilter.min}
          max={hotelDistanceFilter.max}
          currentMin={hotelDistanceFilter.min}
          currentMax={hotelDistanceFilter.current}
          edgeFormatValue={(val) => `${val} km`}
          midFormatValue={(val) => `${val} km`}
          render={({ currentMax, currentMin, min, max, onChange }) => (
            <Slider
              value={currentMax}
              min={min}
              max={max}
              step={0.01}
              onChange={(val) => onChange([val])}
            />
          )}
          onChange={(value) => {
            setHotelDetailsOpenedId('');
            dispatch(
              setHotelDistanceFilter({
                constraint: {
                  min: hotelDistanceFilter.min,
                  max: hotelDistanceFilter.max,
                  current: value[0],
                },
                onlyUpdateConstraint: false,
              }),
            );
          }}
        />
        <CheckboxSelectionFilter
          title={t('hotelSideBarConn.featureFilter.title')}
          options={hotelFeatureFilter.current}
          categoryTranslation={(category) => t(`hotelFeatureCategory.${category}`)}
          onChange={(newOptions) => {
            setHotelDetailsOpenedId('');
            dispatch(
              setHotelFeatureFilter({
                constraint: {
                  current: newOptions,
                },
                onlyUpdateConstraint: false,
              }),
            );
          }}
        />
      </div>
    </Sidebar>
  );
};

export default HotelSideBarConn;
