import * as React from 'react';
import { times } from '@src/shared/src/util/general';

export default (val: number) =>
  times(
    (idx) => (
      <span key={`stf-${idx}`} className={`stars-rating-icon ${idx + 1 > val ? 'is-empty' : ''}`} />
    ),
    5,
  );
