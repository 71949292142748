import * as React from 'react';

// Utils
// import { map, mapIndexed, all, path, concat, pipe, equals, isNil } from '@src/shared/src/util/general';
import { map, mapIndexed } from '@src/shared/src/util/general';
import { t } from '@toolkit/util/i18n';
// Constants
// Actions, Models & Interfaces
import { OptionModel } from '@src/shared/src/models';
// Components
// import { Checkbox } from '@toolkit/ui';
import { CheckboxFilter } from './CheckboxFilter';
import { SidebarSection } from '@toolkit/ui';
// Styles

type Props = {
  title: string;
  depStations: OptionModel[];
  arrStations: OptionModel[];
  isDisabled?: boolean;
  onChange: (depStations: OptionModel[], arrStations: OptionModel[]) => void;
};

export default class StationsFilter extends React.PureComponent<Props> {
  onChangeStationOnly = (stationName: string, isDep: boolean) => {
    const newStations = map(
      (station: OptionModel) =>
        station.label === stationName
          ? { label: stationName, value: String(true) }
          : { label: station.label, value: String(false) },
      isDep ? this.props.depStations : this.props.arrStations,
    );
    this.props.onChange(
      isDep ? newStations : this.props.depStations,
      isDep ? this.props.arrStations : newStations,
    );
  };

  onChangeDepStations = (stationName: string, value: boolean) => {
    const newDepStations = this.props.depStations.map((depStation) =>
      depStation.label === stationName ? { label: stationName, value: String(value) } : depStation,
    );
    this.props.onChange(newDepStations, this.props.arrStations);
  };

  onChangeArrStations = (stationName: string, value: boolean) => {
    const newArrStations = this.props.arrStations.map((arrStation) =>
      arrStation.label === stationName ? { label: stationName, value: String(value) } : arrStation,
    );
    this.props.onChange(this.props.depStations, newArrStations);
  };


  private depCheckboxes = () =>
    mapIndexed(
      (station: OptionModel, index: number) => (
        <CheckboxFilter
          key={`dep-stat-${index}`}
          id={`dep-stat-${index}`}
          label={station.label}
          isChecked={station.value === 'true'}
          onChange={(value) => this.onChangeDepStations(station.label, value)}
          onChangeOnly={(val) => this.onChangeStationOnly(val, true)}
        />
      ),
      this.props.depStations,
    );

  private arrCheckboxes = () =>
    mapIndexed(
      (station: OptionModel, index: number) => (
        <CheckboxFilter
          key={`arr-station-${index}`}
          id={`arr-station-${index}`}
          label={station.label}
          isChecked={station.value === 'true'}
          onChange={(value) => this.onChangeArrStations(station.label, value)}
          onChangeOnly={(val) => this.onChangeStationOnly(val, false)}
        />
      ),
      this.props.arrStations,
    );

  public render() {
    const { title, isDisabled } = this.props;

    return (
      <SidebarSection title={title} isDisabled={isDisabled}>
        <div className="sidebar-section-group">
          <div className="sidebar-section-headline">{t('stationsFilter.title.departure')}</div>
          {this.depCheckboxes()}
        </div>
        <div className="sidebar-section-group">
          <div className="sidebar-section-headline">{t('stationsFilter.title.arrival')}</div>
          {this.arrCheckboxes()}
        </div>
       
      </SidebarSection>
    );
  }
}
