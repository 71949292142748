import * as React from 'react';
import classNames from 'classnames';
import { ChevronIcon } from './icons';
import { Collapse } from './Collapse';

type Props = React.PropsWithChildren & {
  title: string;
  isDisabled?: boolean;
};

const SidebarSection = (props: Props) => {
  const { title, isDisabled, children } = props;
  const [isExpanded, setIsExpanded] = React.useState(false);

  React.useEffect(() => { 
    setIsExpanded(!isDisabled);
  }, [isDisabled]);
  
  return (
    <div
      className={classNames('sidebar-section', {
        'is--open': !isDisabled && isExpanded,
        'is--disabled': isDisabled,
      })}>
      <button className="sidebar-section-title" onClick={() => setIsExpanded(!isExpanded)}>
        {title}
        <ChevronIcon />
      </button>
      <Collapse isExpanded={isExpanded}>
        <div className="sidebar-section-inner">{children}</div>
      </Collapse>
    </div>
  );
};

export default SidebarSection;
